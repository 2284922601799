import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import MediaTile from "../components/MediaTile"
import Button from "../components/Button"
import Mailchimp from "react-mailchimp-form"
import { css } from "emotion"

import headerImg from "../images/2022-02/riley-5.jpg"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Take Action"
      keywords={[`support`, `Alex Riley`, `office`]}
    />
    <MediaTile
      backgroundImage={headerImg}
      backgroundPosition="50% 20%"      
      color="white"      
      minHeight="500px"
    >
      {/* <h1>Take Action</h1> */}
    </MediaTile>
    <div style={{backgroundColor:"#0A273B", width:"100%", height:"auto"}}>
        <h1 className="hero-h1">                                                    
              <span className="hero-text hero-h1">Take Action</span>          
          </h1>    
      </div>
    
    <div style={{height:"100%"}}>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfrS103kte2EIqLg5jvFJgbI5QUL_UMOZMSAXyoAq2C1K_LRg/viewform?embedded=true" height="1200px" width="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  </Layout>
)

export default SecondPage

const mailchimpFormStyles = css`
  display: flex;
  flex-direction: column;

  & > input {
    margin: 10px;
    padding: 15px;
    border: 1px solid #0a273b;
    border-radius: 5px;
  }

  & > button {
    margin: 10px;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #0a273b;
    color: white;
    cursor: pointer;

    transition-property: background-color color border;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;

    &:hover {
      background-color: white;
      border: 1px solid #0a273b;
      color: #0a273b;
    }
  }
`
